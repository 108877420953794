import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Earlier this year, Gatsby released `}<inlineCode parentName="p">{`v3`}</inlineCode>{` of their library. This update introduces
several big improvements to Gatsby sites including incremental builds, fast
refresh, and image processing. The major version change comes with the potential
for breaking changes, this guide aims to cover the most likely issues a site
built with the Carbon Gatsby theme might encounter.`}</p>
    </PageDescription>
    <h2>{`Breaking Changes`}</h2>
    <ol>
      <li parentName="ol">{`Bumped minimum version of Gatsby to `}<inlineCode parentName="li">{`v3`}</inlineCode></li>
      <li parentName="ol">{`Removed the `}<inlineCode parentName="li">{`emotion`}</inlineCode>{` theme provider and its dependenices.`}
        <ul parentName="li">
          <li parentName="ul">{`This impacts sites styling their project using `}<inlineCode parentName="li">{`emotion`}</inlineCode>{` and depending on
theme values supplied by the theme`}</li>
          <li parentName="ul">{`If this applies to your project, you can recreate the theme’s emotion
integration installing `}<inlineCode parentName="li">{`emotion`}</inlineCode>{` and replicating the
`}<a parentName="li" {...{
              "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/857abf691590d86b9384a8f35256e8f54fad299e/packages/gatsby-theme-carbon/src/components/ThemeProvider.js"
            }}><inlineCode parentName="a">{`ThemeProvider`}</inlineCode></a>{`
component and
`}<a parentName="li" {...{
              "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/main/packages/gatsby-theme-carbon/src/util/theme.js"
            }}><inlineCode parentName="a">{`theme.js`}</inlineCode></a>{`.
Finally, you’ll want to shadow
`}<a parentName="li" {...{
              "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/857abf691590d86b9384a8f35256e8f54fad299e/packages/gatsby-theme-carbon/src/util/wrap-root-element.js#L1"
            }}><inlineCode parentName="a">{`wrap-root-element.js`}</inlineCode></a>{`
to add back the `}<inlineCode parentName="li">{`ThemeProvider`}</inlineCode>{` wrapper.`}</li>
        </ul>
      </li>
    </ol>
    <h2>{`Migration guide`}</h2>
    <p>{`In this section we’ll cover the most likely issues site’s built with the Carbon
gatsby theme might encounter when migrating. If you encounter any issues, or
your site leverages more advanced gatsby configuration, check to see if it’s
covered in the
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/docs/reference/release-notes/migrating-from-v2-to-v3/"
      }}>{`official Gatsby v3 migration guide`}</a>{`.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Note: We’ll use yarn for the shell commands here. Replace them with the `}<inlineCode parentName="p">{`npm`}</inlineCode>{`
equivalent if your project uses `}<inlineCode parentName="p">{`npm`}</inlineCode>{` as its package manager.`}</p>
    </InlineNotification>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Update Gatsby`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`yarn add gatsby@latest
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Update gatsby plugins (including the theme)`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`yarn upgrade-interactive --latest
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Check to see if you’re using `}<inlineCode parentName="p">{`sizes`}</inlineCode>{` or `}<inlineCode parentName="p">{`resolutions`}</inlineCode>{` with `}<inlineCode parentName="p">{`gatsby-image`}</inlineCode>{`. Do
a project wide search for `}<inlineCode parentName="p">{`gatsby-image`}</inlineCode>{`. Check to make sure that you’re not
using these
`}<a parentName="p" {...{
            "href": "https://www.gatsbyjs.com/docs/reference/release-notes/migrating-from-v2-to-v3/#removal-of-sizes--resolutions-for-image-queries"
          }}>{`deprecated props`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Check your Sass (and potentially JSON) to ensure they’re imported as ES
Modules. “Default exports” are no longer supported.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-js",
            "metastring": "path=Before",
            "path": "Before"
          }}>{`import styles from './MySpan.module.scss';
import metadata from './metadata.json';

const MySpan = () => <span className={styles.mySpan} />;
`}</code></pre>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-js",
            "metastring": "path=After",
            "path": "After"
          }}>{`import { mySpan } from './MySpan.module.scss';
// Or import * as styles from './MySpan.module.scss'

import * as metadata from './metadata.json';

const MySpan = () => <span className={mySpan} />;
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Make sure to update the path of your shadowed components (Footer and Header). `}</p>
        <ul parentName="li">
          <li parentName="ul">{`Create a folder each for `}<inlineCode parentName="li">{`Footer`}</inlineCode>{` and `}<inlineCode parentName="li">{`Header`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Change the file name to `}<inlineCode parentName="li">{`index.js`}</inlineCode>{` inside each corresponding folder. More info can be found in the `}<a parentName="li" {...{
              "href": "https://gatsby-theme-carbon.vercel.app/guides/shadowing/#shadowing-basics"
            }}>{`shadowing docs`}</a></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you have a custom webpack config, be sure your config is compatible with `}<a parentName="p" {...{
            "href": "https://webpack.js.org/migrate/5/"
          }}>{`Webpack 5`}</a>{` (the version Gatsby 3 uses)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run `}<inlineCode parentName="p">{`npx gatsby clean`}</inlineCode>{` to delete your projects development bundle`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run `}<inlineCode parentName="p">{`yarn dev`}</inlineCode></p>
      </li>
    </ol>
    <h2>{`Troubleshooting`}</h2>
    <ul>
      <li parentName="ul">{`Try deleting your node_modules, running `}<inlineCode parentName="li">{`yarn cache clean`}</inlineCode>{` and `}<inlineCode parentName="li">{`yarn install`}</inlineCode>{`
(or `}<inlineCode parentName="li">{`npm cache clean --force`}</inlineCode>{` and npm install)`}</li>
      <li parentName="ul">{`Feel free to reach out in the #gatsby-theme-carbon slack channel or open an
issue on GitHub`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      